import React from 'react'
import { A } from 'hookrouter'

export default ({ routes }) => (
  <>
    {routes.map(r => (
      <><A href={r}>{r}</A><br/></>
    ))}
  </>
)

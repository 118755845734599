import React from 'react'
import Cell from './Cell'

const Row = ({children, title})=>
<div style={{
  display:'flex', 
  flexDirection:'column'}
}><div style={{fontSize:20, padding:'15px 10px 5px 10px', fontWeight:'normal', color:'#222'}}>{title}</div>
  <div style={{display:'flex', flexWrap: 'wrap'}}>
    {children}
    </div>
    </div>

export default function Matrix ({matrix, checked, check}) { return(
<div style={{padding:20}}>
{Object.entries(matrix).map( 
  ([k,v]) => <Row title={k} key={k}>{
    v.map(
    (c, i)=>
      <Cell 
        key={c} 
        checked={checked[k]>i} 
        index={i}
        onClick={_=>check(k,i+1)}>
         {c}
      </Cell>)
      }
      </Row>
)}
</div>
)}

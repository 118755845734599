import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/App'
import Menu from './components/Menu'
import matrix from './content/matrix.yaml'
import roles from './content/roles.yaml'
import { useRoutes } from 'hookrouter'
import { toLower, keys, pipe, map, replace, fromPairs } from 'ramda'

const toRoute = pipe(
  toLower,
  replace(/\s/, '-'),
  s => `/${s}`
)

const roleMatrix = roleName =>
  fromPairs(
    map(fieldName => [fieldName, matrix[fieldName]], roles[roleName])
  )

const roleRoutes = pipe(
  keys,
  map(k => [
    toRoute(k),
    _ => <App matrix={roleMatrix(k)} role={k} />
  ]),
  fromPairs
)(roles)

const routes = {
  '/menu': () => <Menu routes={map(toRoute, keys(roles))} />,
  ...roleRoutes
}

const MyApp = () => {
  const routeResult = useRoutes(routes)
  return routeResult || roleRoutes['/interface-developer']()
}

ReactDOM.render(<MyApp />, document.getElementById('root'))

import React, { Suspense } from 'react'

const MrDebouncer = React.lazy(() => import('./MrDebouncer.jsx'));

export default function PDFMatrixLazyWrapper(props) {
  performance.measure('react-render')
  return (
      <Suspense fallback={<div>Loading pdf renderer...</div>}>
        <MrDebouncer {...props}/>
      </Suspense>
  );
}
import React from 'react'
import injectSheet from 'react-jss'

const cellColor = ({index ,checked, hover})=>
    checked 
        ? `rgba(40,120,160,${(hover ? 0.5 : 0.3)+index/5})`
        : `rgba(160,160,140,${(hover ? 0.5 : 0.3)+index/5})`

const style = {s:{
    position: 'relative',
    whiteSpace: 'pre-wrap',
    flexGrow: 1,
    flexBasis: 0,
    minWidth:200,
    backgroundColor:cellColor,
    padding:'4px 18px',
    margin: 5,
    fontSize:14,
    lineHeight:1.5,
    cursor:'pointer',
    border: ({checked}) => checked ? '2px inset lightgrey' : '2px outset white',
    borderRadius:3,
    '&:hover': {
        backgroundColor:({index,checked})=>cellColor({index:index-0.5, checked, hover:true})
    },
    '&:after': {
        content: ({checked, _index}) => checked ? `"✓"`: `"-"`, //`"${index+1}"`,
        position: 'absolute',
        bottom:5,
        right:10,
    }
}}

const Cell = ({children, 
               onClick,
               classes})=>
<div className={classes.s} 
    onClick={onClick}>
  <span dangerouslySetInnerHTML={{ __html: children }}></span>
</div>


export default injectSheet(style)(Cell)
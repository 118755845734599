import React from 'react';
import Matrix from './Matrix'
import PDFMatrixLazyWrapper from './PDFMatrix';
import { fromPairs } from 'ramda'

export default function App({matrix, role}) { 

  const title = `
This is <code>${role}</code> self-assessment matrix.<br/>
Please check cells that you feel match your skills, then download a PDF using link at the bottom of the page.<br/>
Please send the downloaded file back via email.`

  const emptyScores  = fromPairs(
    Object.entries(matrix).map(
      ([key, _value]) => [key, 0]))  

  const [checked, setChecked] = React.useState(emptyScores)

  const check = (k,i)=>{
    checked[k]===i ? setChecked({...checked, [k]:i-1}) :  
    setChecked({...checked, [k]:i})}

  return (
<div>
   <h1 style={{fontSize:20, padding:'32px 32px 0px 32px', lineHeight:2, fontWeight:'normal', color:'#222'}}>
      <span dangerouslySetInnerHTML={{ __html: title }}></span>
     <hr/>
    </h1>


  <Matrix 
    matrix={matrix} 
    checked={checked} 
    check={check} />
  <PDFMatrixLazyWrapper matrix={matrix} checked={checked} role={role}/>
</div>
) }
